<template>
  <div class="mx-0 px-0 row w-100">
    <div class="col-md-6 pl-md-0">
      <LiveLectureAttendanceStatsChart />
    </div>
    <div class="col-md-6 p-md-0">
      <el-carousel height="350px" :indicator-position="'none'">
        <el-carousel-item>
          <ExamSubmissionStatistics />
        </el-carousel-item>

        <!-- <el-carousel-item>
          <ElectionStatisticsComponent />
        </el-carousel-item> -->
      </el-carousel>
    </div>
  </div>
</template>

<script>
import ExamSubmissionStatistics from "@/components/exam-submission-statistics.vue";
import LiveLectureAttendanceStatsChart from "@/components/live-lecture-attendance-stats-chart.vue";
// import ElectionStatisticsComponent from "@/components/election-statistics.vue";

export default {
  components: {
    ExamSubmissionStatistics,
    LiveLectureAttendanceStatsChart,
    // ElectionStatisticsComponent,
  },
};
</script>
